<script>
import MeetTheDoctor from "../components/meetthedoctor/MeetTheDoctor.vue";

export default {
  name: "About Us",
  components: {
    MeetTheDoctor,
  },
};
</script>

<template>
  <div class="container mx-auto">
    <MeetTheDoctor />
  </div>
</template>
